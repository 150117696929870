<template>
  <div v-if="tenant.isDefault">
<script v-if="tenant.isDefault" type='application/javascript'>
	window.fwSettings={
	'widget_id':77000003036
	};
	!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}() 
</script>
<script v-if="tenant.isDefault" type='application/javascript' :src="tenant.isDefault?'https://euc-widget.freshworks.com/widgets/77000003036.js':''" async defer></script>

  </div>
</template>

<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();


export default {
  name: "SupportButton",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  computed: {
  },
};
</script>

<style scoped>
</style>