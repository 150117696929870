<template>
  <page-header v-if="event" :title="title || event.name" :logo="event.logo" :img="event.img" :dark="dark" :compact="compact" />
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
export default {
  name: "EventHeader",
  components: {
    PageHeader,
  },
  props: {
    event: Object,
    title: String,
    dark: Boolean,
    compact: Boolean,
  },
  mounted() {
    if (this.event) this.$analytics.enableCustomTrackers(this, this.event.custom_trackers);
  },
  watch: {
    event : function(val) {
      if (this.event) this.$analytics.enableCustomTrackers(this, this.event.custom_trackers);
    },
  }
};
</script>
